<script>
import { ref, watch, computed } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import moment from 'moment'
import SearchFormItem from '@/components/SearchForm/SearchFormItem.vue'
import DatePicker from '@/components/DatePicker.vue'
import formConfigMap from './targetConfig'
import { required } from '@core/utils/validation'
import { asynchronousReturn } from '@core/utils'
import { useMessage } from '@/hooks/useMessage'
import { getBusinessTargetDetail, updateBusinessTarget } from '@/views/set-up/target/service'
import Big from 'big.js'

export default {
  name: 'Configuration',
  components: { SearchFormItem, Dialog, DatePicker },
  props: {
    deptName: {
      type: String,
      default: () => ''
    },
    dept: {
      type: String,
      default: () => ''
    },
    year: {
      type: String,
      default: () => ''
    }
  },
  setup(props, { emit }) {
    const show = ref(true)
    const yearAndMonth = ref(props.year + moment().subtract(0, 'month').format('-MM'))
    const formData = ref([])
    const formRef = ref()
    const { message } = useMessage()

    const total = computed(() => {
      const sum =formData.value.reduce((total, item) => {
        const targetRevenue = new Big(item.targetRevenue || 0)
        const bigCurrentValue = new Big(total)
        return bigCurrentValue.plus(targetRevenue)
      }, 0)
      return sum.toString()
    }) // 目标总收益

    function init() {
      formData.value = JSON.parse(JSON.stringify(formConfigMap[props.dept]))
      getDetail()
    }
    init()
    function getDetail() {
      const data = {
        year: moment(yearAndMonth.value).format('yyyy'),
        month: moment(yearAndMonth.value).format('MM'),
        deptCode: props.dept
      }
      getBusinessTargetDetail(data).then(({ data }) => {
        if (data.status === 200 && data.data && data.data.length > 0) {
          formData.value = JSON.parse(JSON.stringify(data.data.map(item => ({ ...item, targetRevenue: String(item.targetRevenue) }))))
        } else {
          formData.value = JSON.parse(JSON.stringify(formConfigMap[props.dept]))
        }
      }).catch((error) => {
        message.error(error)
      })
    }
    function close() {
      show.value = false
      emit('close')
    }
    function submit() {
      asynchronousReturn(formRef.value.validate).then(() => {
        const month = moment(yearAndMonth.value).format('MM')
        const year = moment(yearAndMonth.value).format('yyyy')
        const data = {
          year,
          month,
          exchangeRate: formData.value,
          deptCode: props.dept
        }
        updateBusinessTarget(data).then(({ data }) => {
          if (data.status === 200) {
            message.success('修改成功')
            close()
            emit('success')
          }
        })
      }).catch(() => {
        message.warning('请填写必填项')
      })
    }

    watch(yearAndMonth, () => {
      getDetail()
    })

    return {
      show,
      yearAndMonth,
      formData,
      total,
      formRef,
      close,
      submit,
      required
    }
  }
}
</script>

<template>
  <Dialog show-footer v-model="show" :title='`配置业务目标（${ deptName }）`' width='500px'  @confirm='submit'  @cancel='close'>
    <div class='fs12 mb8' style='color: #F24545;'>注：收益目标修改后，请重新计算过程数据和核算组数据</div>
    <v-form ref='formRef'>
      <SearchFormItem label='月份'>
        <date-picker v-model='yearAndMonth' type='month' append-icon="mdi-calendar-month" />
      </SearchFormItem>
      <SearchFormItem label='总目标收益' class='mt16'>
        <span>{{ total }}</span>
      </SearchFormItem>
      <SearchFormItem v-for='(item, index) in formData' :key='index' :label='item.groupName' class='mt16'>
        <v-text-field
          v-model='formData[index].targetRevenue'
          type='number'
          :rules="[required, v => (v && v.length <= 10) || ' ']"
          dense
          outlined
          hide-details
        >
          <template #append>
            <span class='flex-center fs15'>美元</span>
          </template>
        </v-text-field>
      </SearchFormItem>
    </v-form>
  </Dialog>
</template>

<style scoped lang='scss'>
::v-deep .form-item-label {
  width: 80px;
  text-align: right;
}

::v-deep .v-input__append-inner {
  height: 23px;
}
</style>
