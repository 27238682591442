<script>
export default {
  name: 'ListItem',
  props: {
    color: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div class='flex-left gap6' :class="{ 'list-item': color }" :style="{ '--color': color }">
    <slot />
  </div>
</template>

<style scoped lang='scss'>
.list-item {
  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--color);
  }
}
</style>
