<script>
import Dialog from '@/components/Dialog/index.vue'
import { ref } from '@vue/composition-api'
import SuperTable from '@/components/SuperTable.vue'

export default {
  name: 'ImportError',
  components: { SuperTable, Dialog },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const show = ref(true)

    function close() {
      show.value = false
      emit('close')
    }

    const tableColumns = [
      { props: 'num', label: '行数', width: 80},
      { props: 'reason', label: '失败原因'},
    ]

    return {
      show,
      tableColumns,
      close,
    }
  }
}
</script>

<template>
  <Dialog
    v-model="show"
    show-footer
    :show-cancel-btn-text='false'
    confirmBtnText='知道了'
    title='导入失败'
    width='500px'
    @confirm='close'
    @cancel='close'
  >
    <div class='fs14 mb12'>导入失败，请修改后重新导入</div>
    <div style='max-height: 300px'>
      <SuperTable :columns='tableColumns' :data='list'>
        <template #empty>
          <div>暂无错误数据</div>
        </template>
      </SuperTable>
    </div>
  </Dialog>
</template>

<style scoped lang='scss'>

</style>
