<script>
import SuperTable from '@/components/SuperTable.vue'
import { ref } from '@vue/composition-api'
import { deleteOutput, getOutputList } from './service'
import { useMessage } from '@/hooks/useMessage'

export default {
  name: 'Output',
  components: { SuperTable },
  props: {
    searchForm: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const tableColumns = [
      { props: 'userName', label: '姓名'},
      { props: 'oneDeptName', label: '一级部门' },
      { props: 'twoDeptName', label: '二级部门' },
      { props: 'threeDeptName', label: '三级部门' },
      { props: 'groupProperties', label: '组别性质' },
      { props: 'channelIdNum', label: '频道数量', align: 'right' },
      { props: 'incomeDollar', label: '归属收益($)', align: 'right' },
      { props: 'incomeRmb', label: '归属收益(￥)', align: 'right' },
      { props: 'operate', label: '操作', width: 130 },
    ]
    const loading = ref(true)
    const tableData = ref([])
    const { confirm, message } = useMessage()

    function init() {
      loading.value = true
      getOutputList(props.searchForm).then(({ data }) => {
        tableData.value = data.data.outputVoList || [] // 列表数据
        emit('change', data.data.bizStatisticsVO || {}) // 表格顶部数据
        emit('totalChange', data.data.bizStatisticsVO.total)
        emit('isItCovered', tableData.value.length > 0)
        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }
    function openDetail(row) {
      emit('openDetail', row)
    }

    function deleteRow(row) {
      confirm('删除后，请重新计算部门过程数据及提成，是否继续删除？', { type: 'warning' }).then(() => {
        deleteOutput({
          deptCode: props.searchForm.dept,
          employeeUserId: row.employeeUserId,
          yearAndMonth: props.searchForm.yearAndMonth,
          deptThreeId: row.threeDeptId,
          deptTwoId: row.twoDeptId
        }).then(res => {
          if (res.data.status === 200) {
            init()
            message.success('删除成功！')
          } else {
            message.error('删除失败，请稍后重试！')
          }
        })
      })
    }

    return {
      tableColumns,
      tableData,
      init,
      openDetail,
      loading,
      deleteRow,
    }
  }
}
</script>

<template>
  <super-table :loading='loading' :columns="tableColumns" :data="tableData">
    <template #operate="{ row }">
      <span class='app-link' @click='openDetail(row)'>明细</span>
      <span class='app-link' @click='deleteRow(row)'>删除</span>
    </template>
  </super-table>
</template>

<style scoped lang='scss'>

</style>
