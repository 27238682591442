<script>
import { AliOSS } from '@core/utils/useAliOSS'
import { ref } from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
export default {
  name: 'UploadFiles',
  props: {
    bucket: {
      type: String,
      default: '',
    },
    acceptType: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '上传文件',
    },
    appendOuterIcon: {
      type: String,
      default: '',
    },
    prependInnerIcon: {
      type: String,
      default: 'mdi-tray-arrow-up',
    },
    isOss: { // 是否上传oss
      type: Boolean
    },
    size: {
      type: Number,
      default: 1024 * 10,
    },
    limit: {
      type: Number,
      default: 99999,
    },
    checkErrorText: {
      type: String,
      default: '上传文件格式有误,请重新选择！'
    }
  },
  setup(props, { emit }) {
    const aliOss = new AliOSS(props.bucket)
    const { message } = useMessage()
    const { userId } = JSON.parse(localStorage.getItem('userData')).user
    const file = ref(undefined) // 选中文件
    const UploadRef = ref()
    const fileDetails = ref({})
    const fileUploadedKey = ref('')

    // 获取文件前缀
    function getPrefixPath() {
      const date = new Date()
      const getFormatDate = () => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`

      return `operationLocal/${getFormatDate()}/${userId}/${date.valueOf()}`
    }
    function uploadClick() {
      // 手动触发上传
      UploadRef.value.$refs.input.click()
    }

    // 文件格式检验
    function checkFileType(fileData) {
      const fileType = fileData.name.split('.').pop()
      const acceptType = props.acceptType?.split(',') || []
      if (acceptType.length === 0) return true

      return acceptType.some(item => item === `.${fileType}`)
    }
    // 上传文件
    function upload() {
      message.info('文件上传中...')
      if (file.value) {
        // 刷新权限,开始上传
        aliOss.fetchOSS().then(() => {
          const data = file.value
          fileDetails.value.size = data.size
          fileDetails.value.name = data.name
          fileDetails.value.bucket = props.bucket
          const prefixPath = getPrefixPath()
          const fileNameUpload = `${prefixPath}_${data.name}`
          aliOss.fileUploadMulti(fileNameUpload, data).then(() => {
            aliOss.list(fileNameUpload).then(result => {
              if (result.objects.length) {
                /* 阿里云中存在同名文件（上传成功） */
                fileUploadedKey.value = fileNameUpload
                fileDetails.value.key = fileUploadedKey.value

                // 上传成功触发的事件
                emit('onSuccess', fileDetails.value)
                const time = setTimeout(() => {
                  fileDetails.value = {}
                  file.value = undefined
                  clearTimeout(time)
                }, 1000)
              } else {
                message.error('文件上传失败，请重新上传！')
              }
            }).catch(error => {
              // 取消上传
              if (error.name === 'cancel') return
              message.error('上传失败，请重试!')
            })
          })
        })
      }
    }

    // 清空
    function clear() {
      file.value = undefined
      fileDetails.value = {}
      fileUploadedKey.value = ''
    }

    function fileChange(val) {
      if (!val) return false

      // 进行格式检验
      const inspect = checkFileType(val)
      if (!inspect) {
        file.value = undefined
        message.error(props.checkErrorText)
        clear()

          return false
      }

      // 文件大小校验
      if ((file.value.size / 1024/1024) > props.size) {
        message.warning('上传文件超出限制，请重新选择！')
        file.value = undefined

        return false
      }

      if (props.isOss) upload() // 上传oss
      else emit('onSuccess', file.value)
    }

    return {
      file,
      uploadClick,
      UploadRef,
      fileChange,
      clear,
    }
  }
}
</script>

<template>
  <div style="display: inline-block">
    <v-file-input
      ref="UploadRef"
      v-model="file"
      :accep="acceptType"
      style="display: none"
      name="file"
      label="测试"
      outlined
      dense
      hide-details="auto"
      clearable
      prepend-icon=""
      v-bind="$attrs"
      @change="fileChange"
    />
    <v-btn
      v-bind="$attrs"
      @click="uploadClick"
    >
      <v-icon
        v-if="prependInnerIcon"
        left
      >
        {{ prependInnerIcon }}
      </v-icon>
      {{ label }}
      <v-icon
        v-if="appendOuterIcon"
        class="ml8"
        right
      >
        {{ appendOuterIcon }}
      </v-icon>
    </v-btn>
  </div>
</template>

