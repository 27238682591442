<script>
import ViewTemplate from '@/views/business/components/ViewTemplate.vue'

export default {
  name: 'List',
  components: { ViewTemplate },
}
</script>

<template>
  <ViewTemplate dept='SLICE_BD' />
</template>
