import http from '@http'

// 获取月度业务数据产出列表
export function getOutputList(data) {
  return http.post('/bizData/output/list', data)
}

// 获取月度业务数据赋能列表
export function getEnablingList(data) {
  return http.post('/bizData/empowerment/list', data)
}

// 产出明细
export function getOutputDetail(data) {
  return http.post('/bizData/outputDetail', data)
}

// 删除产出数据
export function deleteOutput(data) {
  return http.post('/bizData/output/delete', data)
}

// 导入数据
export function importData(data) {
  return http.post('/bizData/output/import', data)
}

// 设置内部结算金额
export function setInternalSettlement(data) {
  return http.post('/bizData/settlement', data)
}

// 按部门获取员工列表
export function getEmployeeList(data) {
  return http.post('/bizData/getUserByDeptName', data)
}

// 新增赋能人
export function addEnabling(data) {
  return http.post('/bizData/empowerment/add', data)
}

// 编辑赋能
export function editEnabling(data) {
  return http.post('/bizData/empowerment/edit', data)
}

// 删除赋能人员
export function deleteEnabling(data) {
  return http.post('/bizData/empowerment/delete', data)
}

// 获取内部结算金额
export function getInternalSettlement(data) {
  return http.post('/bizData/settlement/get', data)
}
