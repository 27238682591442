<script>
import SuperTable from '@/components/SuperTable.vue'
import { ref } from '@vue/composition-api'
import { deleteEnabling, getEnablingList } from '@/views/business/components/service'
import { useMessage } from '@/hooks/useMessage'

export default {
  name: 'Enabling',
  methods: { deleteEnabling },
  components: { SuperTable },
  props: {
    searchForm: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const tableColumns = [
      { props: 'employeeName', label: '姓名'},
      { props: 'oneDeptName', label: '一级部门' },
      { props: 'twoDeptName', label: '二级部门' },
      { props: 'threeDeptName', label: '三级部门' },
      { props: 'bonusPercentage', label: '奖金包占比', width: 100, align: 'right' },
      { props: 'operate', label: '操作', width: 100 },
    ]
    const tableData = ref([])
    const loading = ref(true)
    const { confirm, message } = useMessage()
    function init() {
      loading.value = true
      getEnablingList(props.searchForm).then(({ data }) => {
        tableData.value = data.data.list // 列表数据
        emit('change', data.data) // 表格顶部数据
        emit('totalChange', data.data.userNum)
        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    function editEnabling(row) {
      emit('editEnabling', row)
    }

    function deleteEnablingPeople(val) {
      confirm('您确认要删除该员工嘛?', { type: 'warning' }).then(() => {
        deleteEnabling({ id: val.id }).then(() => {
          init()
          message.success('删除成功')
        })
      })
    }

    return {
      tableColumns,
      tableData,
      loading,
      init,
      editEnabling,
      deleteEnablingPeople
    }
  }
}
</script>

<template>
  <super-table :loading='loading' :columns="tableColumns" :data="tableData">
    <template #operate="{ row }">
      <span class='app-link' @click='editEnabling(row)'>编辑</span>
      <span class='app-link' @click='deleteEnablingPeople(row)'>删除</span>
    </template>
  </super-table>
</template>

<style scoped lang='scss'>

</style>
