<script>
import Dialog from '@/components/Dialog/index.vue'
import SearchFormItem from '@/components/SearchForm/SearchFormItem.vue'
import DatePicker from '@/components/DatePicker.vue'
import { ref, reactive } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { asynchronousReturn } from '@core/utils'
import moment from 'moment'
import { useMessage } from '@/hooks/useMessage'
import { updateRate } from '@/views/set-up/exchangeRate/service'

export default {
  name: 'EditRate',
  components: { SearchFormItem, Dialog, DatePicker },
  props: {
    row: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const show = ref(true)
    const formRef = ref()
    const { message } = useMessage()
    const formData = reactive({
      yearMonth: moment().subtract(0, 'month').format('yyyy-MM'),
      exchangeRate: undefined,
      currency: '美元',
      id: undefined
    })
    if (JSON.stringify(props.row) !== '{}') {
      formData.id = props.row.id
      formData.exchangeRate = String(props.row.exchangeRate)
      formData.currency = props.row.currency
      formData.yearMonth = props.row.yearAndMonth
    }

    function close() {
      show.value = false
      const time = setTimeout(() => {
        emit('close')
      }, 300)
    }

    function submit() {
      asynchronousReturn(formRef.value.validate).then(() => {
        updateRate(formData).then(({ data }) => {
          if (data.status === 200) {
            message.success('保存成功！')
            emit('success')
            close()
          }
        }).catch((error) => {
          message.error(error || '保存失败，请稍后重试！')
        })
      })
    }

    return {
      show,
      formData,
      formRef,
      submit,
      close,
      required
    }
  }
}
</script>

<template>
  <Dialog show-footer v-model="show" title='新增/编辑汇率' width='400px'  @confirm='submit'  @cancel='close'>
    <div class='fs14'>
      <v-form ref='formRef'>
        <SearchFormItem label='月份'>
          <date-picker v-model='formData.yearMonth' :rules='[required]' type='month' min='2024-01' append-icon='mdi-calendar' />
        </SearchFormItem>
        <SearchFormItem label='币种' class='mt12'>
          <span class='fs15'>美元</span>
        </SearchFormItem>
        <SearchFormItem label='汇率' class='mt12'>
          <v-text-field
            v-model='formData.exchangeRate'
            :rules="[required, v => (v && v.length <= 10) || ' ', (v => Number(v) >= 0) || ' '] "
            maxlength="5"
            type='number'
            placeholder='长度小于等于5的正数'
            dense
            outlined
            hide-details
          />
        </SearchFormItem>
      </v-form>
    </div>
  </Dialog>
</template>

<style scoped lang='scss'>

</style>
