<script>
import { ref } from '@vue/composition-api'
import SuperTable from '@/components/SuperTable.vue'
import Pagination from '@/components/Pagination.vue'
import Dialog from '@/components/Dialog/index.vue'
import ListItem from '@/components/ListItem.vue'
import { importData } from './service'
import UploadFiles from '@/components/UploadFiles/index.vue'
import { useMessage } from '@/hooks/useMessage'

export default {
  name: 'InputOutput',
  components: { ListItem, Pagination, SuperTable, Dialog, UploadFiles },
  props: {
    yearAndMonth: String,
    dept: String,
    isItCovered: Boolean
  },
  setup(props, { emit }) {
    const { message, confirm, loading } = useMessage()
    const show = ref(true)
    const uploadRef = ref()
    const file = ref(undefined)
    const fileName = ref(undefined)
    const deptMap = {
      YT_BD: 'YT事业部',
      FB_BD: 'FB事业部',
      SLICE_BD: '影视事业部',
      X_BD: 'X项目',
      ORIGINAL_BD: 'X项目',
    }
    function success(fil) {
      file.value = fil
      fileName.value = fil.name
    }

    function deleteFile() {
      uploadRef.value.clear()
      file.value = undefined
      fileName.value = undefined
    }
    function isJSON(string) {
      return /^\s*[\[{].*[\]}]\s*$/.test(string);
    }

    function serve() {
      const formData = new FormData()
      formData.append('file', file.value)
      formData.append('deptCode', props.dept)
      formData.append('yearAndMonth', props.yearAndMonth)
      const loadingRef = loading()
      importData(formData).then(() => {
        message.success('导入成功')
        emit('success')
        emit('close')
        loadingRef.close()
      }).catch(error => {
        loadingRef.close()
        if (isJSON(error)) {
          emit('error', JSON.parse(error))
        } else {
          message.error(error)
        }
        // 清空上传文件
        deleteFile()
      })
    }

    function submit() {
      if (!file.value) return message.warning('请选择文件')
      if (props.isItCovered) {
        confirm('导入后，员工数据将会覆盖，是否继续？', { type: 'warning' }).then(() => {
          serve()
        })
      } else {
        serve()
      }

    }

    function close() {
      show.value = false
      emit('close')
    }
    const text = '<员工收益数据模板>'

    function downloadXSL() {
      const link = document.createElement('a')
      link.href = '/员工收益数据模板.xlsx'
      link.download = '员工收益数据模板.xlsx'
      link.click()
    }

    return {
      show,
      file,
      fileName,
      uploadRef,
      success,
      deleteFile,
      submit,
      close,
      text,
      downloadXSL
    }
  }
}
</script>

<template>
  <Dialog v-model="show" title='导入' width='500px' show-footer @confirm='submit' @cancel='close'>
    <div class='flex-vertical gap8 tips'>
      <div>1、下载<span class='download-link' @click='downloadXSL'>{{ text }}</span>；</div>
      <div>2、模板中填写好收益信息，上限5000条；</div>
      <div>3、选择填好的文件进行上传，大小不超过5M；</div>
    </div>
    <div class='mt20 fs14'>月份：{{ yearAndMonth }}</div>
    <div class='mt8'>
      <upload-files
        ref='uploadRef'
        color='primary'
        accept-type=".xlsx,.xls"
        :size='5'
        :limit='1'
        :disabled='!!fileName'
        check-error-text='仅支持xls和xlsx格式文件上传'
        @onSuccess='success'
      />
      <div v-if='fileName' class='flex-between mt8'>
        <div>{{ fileName }}</div>
        <v-icon @click='deleteFile'> mdi-close </v-icon>
      </div>
    </div>
  </Dialog>
</template>

<style scoped lang='scss'>
.tips {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.78);
}
</style>
