<script>
import Dialog from '@/components/Dialog/index.vue'
import { ref } from '@vue/composition-api'
import SearchFormItem from '@/components/SearchForm/SearchFormItem.vue'
import DatePicker from '@/components/DatePicker.vue'
import moment from 'moment/moment'
import { useMessage } from '@/hooks/useMessage'
import { calculateCommission } from './service'

export default {
  name: 'CalculateCommission',
  components: { DatePicker, SearchFormItem, Dialog },
  props: {
    dept: {
      type: String,
      default: () => ''
    }
  },
  setup(props, { emit }) {
    const show = ref(true)
    const yearAndMonth = ref(moment().subtract(1, 'month').format('yyyy-MM'))
    const currentYearMonth = ref(moment().subtract(1, 'month').format('yyyy-MM'))
    const { message, confirm } = useMessage()

    // 时间比较
    function timeComparison(time1, time2) {
      if (time1 === time2) return  true
      const date1 = new Date(time1);
      const date2 = new Date(time2);
      return date1 > date2
    }

    function close() {
      show.value = false
      emit('close')
    }

    function count() {
      const data = {
        deptCode: props.dept,
        yearAndMonth: yearAndMonth.value
      }
      calculateCommission(data).then(({ data }) => {
        if (data.status === 200) {
          message.success('计算成功')
          emit('success')
          close()
        }
      }).catch((error) => {
        message.error(error || '计算失败，请稍后重试！')
      })
    }
    function submit() {
      if (!timeComparison(yearAndMonth.value, currentYearMonth.value)) {
        confirm('请注意，所选月份为历史核算月！！！', { type: 'warning', confirmBtnText: '仍要计算'}).then(() => {
          count()
        })
      } else count()
    }

    return {
      show,
      yearAndMonth,
      currentYearMonth,
      submit,
      close
    }
  }
}
</script>

<template>
  <Dialog show-footer v-model="show" title='提示' width='400px'  @confirm='submit'  @cancel='close'>
    <div class='tip'>
      <img class='mt5' src='../../../assets/images/waring.png' alt='' style='width: 15px'>
      <div>发起计算前，请查看基础数据中所属月份信息是否准确，否则将会影响计算结果，是否发起计算？</div>
    </div>
    <SearchFormItem label='计算月份' class='mt15'>
      <DatePicker
        v-model='yearAndMonth'
        type='month'
        placeholder='请选择'
        append-icon="mdi-calendar-month"
      />
    </SearchFormItem>
  </Dialog>
</template>

<style scoped lang='scss'>
.tip {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 15px;
}
</style>
