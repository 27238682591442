<script>
import { computed, ref, watch } from '@vue/composition-api'
import AppView from '@/components/AppView.vue'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import TabsPro from '@/components/tabs/TabsPro.vue'
import DatePicker from '@/components/DatePicker.vue'
import { getBusinessTargetList } from '@/views/set-up/target/service'
import SuperTable from '@/components/SuperTable.vue'
import moment from 'moment/moment'
import Configuration from './Configuration.vue'
import { useMessage } from '@/hooks/useMessage'

export default {
  name: 'List',
  components: { SuperTable, TabsPro, TabPanePro, AppView, DatePicker, Configuration },
  props: {
    value: {
      type: String,
      default: () => ''
    }
  },
  setup() {
    const year = ref(moment().subtract(1, 'month').format('yyyy'))
    const activeMonth = ref(Number(moment().subtract(1, 'month').format('M')))
    const activeYear = ref(moment().format('yyyy'))
    const deptMap = {
      YT_BD: 'YT事业部',
      FB_BD: 'FB事业部',
      SLICE_BD: '影视事业部',
      X_BD: 'X项目',
      ORIGINAL_BD: '原创事业部',
    }
    const deptOption = ref([
      { label: 'YT事业部', value: 'YT_BD' },
      { label: 'FB事业部', value: 'FB_BD' },
      { label: '影视事业部', value: 'SLICE_BD' },
      { label: 'X项目', value: 'X_BD' },
      // { label: '原创事业部', value: 'ORIGINAL_BD' },
    ])
    const activeName = ref('YT_BD')
    const tableData = ref([])
    const { message } = useMessage()
    const visibleConfiguration = ref(false)
    const tableColumns = [
      { props: 'groupName', label: '', width: 150, keyValue: 0},
      { props: 'month1', label: '1月', align: 'right', keyValue: 1 },
      { props: 'month2', label: '2月', align: 'right', keyValue: 2 },
      { props: 'month3', label: '3月', align: 'right', keyValue: 3 },
      { props: 'month4', label: '4月', align: 'right', keyValue: 4 },
      { props: 'month5', label: '5月', align: 'right', keyValue: 5 },
      { props: 'month6', label: '6月', align: 'right', keyValue: 6 },
      { props: 'month7', label: '7月', align: 'right', keyValue: 7 },
      { props: 'month8', label: '8月', align: 'right', keyValue: 8 },
      { props: 'month9', label: '9月', align: 'right', keyValue: 9 },
      { props: 'month10', label: '10月', align: 'right', keyValue: 10 },
      { props: 'month11', label: '11月', align: 'right', keyValue: 11 },
      { props: 'month12', label: '12月', align: 'right', keyValue: 12 },
      { props: 'monthTotal', label: '合计', align: 'right', keyValue: 13 },
    ]
    const loading = ref(true)

    function init() {
      const data = {
        deptCode: activeName.value,
        year: year.value
      }
      loading.value = true
      tableData.value = []
      getBusinessTargetList(data).then(({ data }) => {
        if (data.status === 200) {
          tableData.value = data.data
        }
        loading.value = false
      }).catch((error) => {
        console.log(error, 'aca')
        message.error(error || '获取数据失败！')
        loading.value = false
      })
    }
    init()

    function tabClick() {
      const timer = setTimeout(() => {
        init()
        clearTimeout(timer)
      }, 500)
    }

    function openConfig() {
      visibleConfiguration.value = true
    }

    function headerCellStyle ({ column }) {
      if (activeYear.value !== String(year.value)) return ({})
      const data = tableColumns.find(item => item.keyValue === activeMonth.value)
      if (data && column.field === data.props) {
        return {
          backgroundColor: 'rgba(44,160,248,0.06)',
        }
      }
    }
    function cellStyle ({ row, column }) {
      if (activeYear.value !== String(year.value)) return ({})
      const data = tableColumns.find(item => item.keyValue === activeMonth.value)
      if (data && column.field === data.props) {
        return {
          backgroundColor: 'rgba(44,160,248,0.06)',
        }
      }
    }

    watch(year, () => {
      init()
    })


    return {
      year,
      deptOption,
      activeName,
      deptMap,
      tableColumns,
      tableData,
      tabClick,
      openConfig,
      init,
      loading,
      visibleConfiguration,
      headerCellStyle,
      cellStyle
    }
  }
}
</script>

<template>
  <app-view>
    <template #header>
      <div class='flex-vertical mb20' style='width: 100%'>
        <tabs-pro v-model='activeName' @tabClick='tabClick'>
          <tab-pane-pro v-for='item in deptOption' :key='item.value' :label="item.label" :name="item.value" />
        </tabs-pro>
        <div class='flex-left mt20 gap20'>
          <div style='width: 200px'>
            <date-picker type='year' v-model='year' append-icon="mdi-calendar-month" />
          </div>
          <v-btn color='primary' @click='openConfig'>配置业务目标</v-btn>
        </div>
      </div>
    </template>
    <SuperTable :loading='loading' :columns='tableColumns' :data='tableData' :cell-style="cellStyle" :header-cell-style="headerCellStyle" />

    <Configuration
      v-if='visibleConfiguration'
      :dept-name='deptMap[activeName]'
      :dept='activeName'
      :year='year'
      @close='visibleConfiguration = false'
      @success='init'
    />
  </app-view>
</template>

<style scoped lang='scss'>
::v-deep .tab-content-item-box {
  .tab-pane-pro {
    padding: 0;
  }
}
</style>
