<script>
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import TabsPro from '@/components/tabs/TabsPro.vue'
import { ref } from '@vue/composition-api'
import ViewTemplate from '@/views/business/components/ViewTemplate.vue'

export default {
  name: 'ViewComponents',
  components: { ViewTemplate, TabsPro, TabPanePro },
  props: {
    dept: {
      type: String,
      default: ''
    }
  },
  setup() {
    const activeName = ref("output")

    return {
      activeName
    }
  }
}
</script>

<template>
  <v-card elevation='0' style='height: 100%; padding-top: 20px'>
    <tabs-pro v-model='activeName' style='height: 100%'>
      <tab-pane-pro label="月度业务数据（产出）" name="output">
        <ViewTemplate :dept='dept' active-name='output' />
      </tab-pane-pro>
      <tab-pane-pro label="月度业务数据（赋能）" name="enabling">
        <ViewTemplate :dept='dept' active-name='enabling' />
      </tab-pane-pro>
    </tabs-pro>
  </v-card>
</template>

<style scoped lang='scss'>
::v-deep .tab-content-item-box {
  height: calc( 100% - 50px);
  .tab-pane-pro {
    padding: 0;
    height: 100%;
  }
}

::v-deep .tab-nav-item-box {
  margin: 0 20px;
  width: calc(100% - 40px);
}
</style>
