import http from '@http'

// 获取业务目标配置
export function getBusinessTargetList(data) {
  return http.post('/targetRevenue/list', data)
}

// 获取业务目标配置详情
export function getBusinessTargetDetail(data) {
  return http.post('/targetRevenue/getDetailList', data)
}

// 更新业务目标配置
export function updateBusinessTarget(data) {
  return http.post('/targetRevenue/addOrEdit', data)
}
