<script>
import Dialog from '@/components/Dialog/index.vue'
import { ref } from '@vue/composition-api'
import { getEmployeeList } from '@/views/business/components/service'
import SearchFormItem from '@/components/SearchForm/SearchFormItem.vue'
import { useMessage } from '@/hooks/useMessage'
import { setInternalSettlement } from './service'

export default {
  name: 'SetUpResponsiblePerson',
  components: { SearchFormItem, Dialog },
  props: {
    row: {
      type: Object,
      default: () => ({})
    },
    dept: {
      type: String,
      default: () => ''
    },
    yearAndMonth: {
      type: String,
      default: () => ''
    }
  },
  setup(props, { emit }) {
    const show = ref(true)
    const userList = ref([])
    const { message } = useMessage()
    const chargePersonId = ref([])
    const deptMap = {
      YT_BD: 'YT事业部',
      FB_BD: 'FB事业部',
      SLICE_BD: '影视事业部',
      X_BD: 'X项目',
      ORIGINAL_BD: '原创事业部',
    }


    function init() {
      if (props.row.chargePersonId) {
        chargePersonId.value = props.row.chargePersonId.split(',')
      }
      getEmployeeList({}).then(({ data }) => {
        if (data.status === 200) {
            userList.value = data.data.map(item => ({ text: item.userName, value: item.userId }))
          }
      })
    }

    init()

    function close() {
      show.value = false
      emit('close')
    }
    function submit() {
      const peopleList = userList.value.filter(item => chargePersonId.value.includes(item.value))
      if (peopleList.length === 0) return message.warning('请选择负责人')
      const chargePerson = peopleList.map(item => item.text).join(',')
      const chargePersonData = peopleList.map(item => item.value).join(',')
      const dat = {
        chargePersonId: chargePersonData,
        chargePerson,
        deptCode: props.dept,
        yearAndMonth: props.yearAndMonth
      }

      setInternalSettlement(dat).then(({ data }) => {
        if (data.status === 200) {
          message.success('设置成功！')
          close()
          emit('success')
        }
      })
    }

    return {
      show,
      userList,
      chargePersonId,
      submit,
      close
    }
  }
}
</script>

<template>
  <Dialog show-footer v-model="show" title='负责人设置' width='500px'  @confirm='submit'  @cancel='close'>
    <SearchFormItem label='负责人'>
      <v-autocomplete v-model='chargePersonId' multiple dense outlined :items="userList" hide-details />
    </SearchFormItem>
  </Dialog>
</template>

<style scoped lang='scss'>

</style>
