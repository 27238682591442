import http from '@http'

// 获取汇率列表
export function getRateList(data) {
  return http.post('/rate/getPageInfo', data)
}

// 更新汇率
export function updateRate(data) {
  return http.post('/rate/addOrEdite', data)
}
