<script>
import AppView from '@/components/AppView.vue'
import SuperTable from '@/components/SuperTable.vue'
import { ref, reactive } from '@vue/composition-api'
import Pagination from '@/components/Pagination.vue'
import { getRateList } from './service'
import EditRate from './EditRate.vue'

export default {
  name: 'List',
  components: { EditRate, Pagination, SuperTable, AppView },
  setup() {
    const tableData = ref([])
    const tableColumns = [
      { props: 'yearAndMonth', label: '月份' },
      { props: 'currency', label: '币种' },
      { props: 'exchangeRate', label: '汇率' },
      { props: 'createdUser', label: '创建人' },
      { props: 'createdAt', label: '创建时间' },
      { props: 'operate', label: '操作', width: 80 },
    ]
    const page = reactive({
      total: 0,
      page: 1,
      pageSize: 20,
    })

    function init() {
      getRateList({ ...page, total: undefined }).then(({ data }) => {
        if (data.status === 200) {
          const { records, total } = data.data
          page.total = total
          tableData.value = records
        }
      })
    }
    init()

    const visibleEdit = ref(false)
    const editRow = ref({})

    function openEdit(row) {
      editRow.value = row
      visibleEdit.value = true
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    return {
      tableData,
      tableColumns,
      page,
      visibleEdit,
      openEdit,
      editRow,
      init,
      handleCurrentChange,
      handleSizeChange,
    }
  }
}
</script>

<template>
  <AppView>
    <template #main-header>
      <div class='mb12'>
        <v-btn color='primary' @click='openEdit({})'>新增汇率</v-btn>
      </div>
    </template>

    <SuperTable :data='tableData' :columns='tableColumns'>
      <template #operate="{ row }">
        <span class='app-link' @click='openEdit(row)'>编辑</span>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <EditRate v-if='visibleEdit' :row='editRow' @close='visibleEdit = false' @success='init' />
  </AppView>
</template>

<style scoped lang='scss'>

</style>
