import http from '@http'

// 查询过程数据
export function getProcessData(params) {
  return http.post('/processData/calculate/query', params)
}

// 计算提成
export function calculateCommission(params) {
  return http.post('/processData/calculate/calculateCommission', params)
}
