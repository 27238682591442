<script>
import AppView from '@/components/AppView.vue'
import { ref, watch } from '@vue/composition-api'
import TabsPro from '@/components/tabs/TabsPro.vue'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import DatePicker from '@/components/DatePicker.vue'
import moment from 'moment'
import ListItem from '@/components/ListItem.vue'
import SuperTable from '@/components/SuperTable.vue'
import { getProcessData } from './service'
import CalculateCommission from './CalculateCommission.vue'

export default {
  name: 'ProcessTemplate',
  components: { SuperTable, ListItem, TabPanePro, TabsPro, AppView, DatePicker, CalculateCommission },
  props: {
    dept: {
      type: String,
      default: () => ''
    }
  },
  setup(props) {
    const deptMap = {
      YT_BD: 'YT事业部',
      FB_BD: 'FB事业部',
      SLICE_BD: '影视事业部',
      X_BD: 'X项目',
      ORIGINAL_BD: '原创事业部',
    }
    const activeName = ref('commission')
    const nameMap = {
      execution: '完成',
      commission: '提成'
    }
    const year = ref(moment().subtract(1, 'month').format('yyyy'))
    const activeMonth = ref(Number(moment().subtract(1, 'month').format('M')))
    const activeYear = ref(moment().format('yyyy'))
    const tableColumns = [
      { props: 'category', label: '类别', width: 150, keyValue: 0},
      { props: 'month1', label: '1月', align: 'right', keyValue: 1 },
      { props: 'month2', label: '2月', align: 'right', keyValue: 2 },
      { props: 'month3', label: '3月', align: 'right', keyValue: 3 },
      { props: 'month4', label: '4月', align: 'right', keyValue: 4 },
      { props: 'month5', label: '5月', align: 'right', keyValue: 5 },
      { props: 'month6', label: '6月', align: 'right', keyValue: 6 },
      { props: 'month7', label: '7月', align: 'right', keyValue: 7 },
      { props: 'month8', label: '8月', align: 'right', keyValue: 8 },
      { props: 'month9', label: '9月', align: 'right', keyValue: 9 },
      { props: 'month10', label: '10月', align: 'right', keyValue: 10 },
      { props: 'month11', label: '11月', align: 'right', keyValue: 11 },
      { props: 'month12', label: '12月', align: 'right', keyValue: 12 },
      { props: 'total', label: '合计', align: 'right', keyValue: 13 },
    ]
    const tableData = ref([])
    const visibleCount = ref(false)
    const loading = ref(true)
    const lastUpdatedTime = ref('-')

    function init() {
      const data = {
        deptCode: props.dept,
        type: nameMap[activeName.value],
        year: year.value
      }
      loading.value = true
      getProcessData(data).then(({ data }) => {
        if (data.status === 200) {
          tableData.value = data.data
          if (data.data.length > 0) {
            const timeList = tableData.value.map(item => new Date(item.lastTime).getTime())
            const maxTime = Math.max(...timeList);
            lastUpdatedTime.value = moment(maxTime).format('YYYY-MM-DD HH:mm:ss')
          }
        }
        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    init()


    function headerCellStyle ({ column }) {
      if (activeYear.value !== String(year.value)) return ({})
      const data = tableColumns.find(item => item.keyValue === activeMonth.value)
      if (data && column.field === data.props) {
        return {
          backgroundColor: 'rgba(44,160,248,0.06)',
        }
      }
    }
    function cellStyle ({ _, column }) {
      if (activeYear.value !== String(year.value)) return ({})
      const data = tableColumns.find(item => item.keyValue === activeMonth.value)
      if (data && column.field === data.props) {
        return {
          backgroundColor: 'rgba(44,160,248,0.06)',
        }
      }
    }
    function tabChange(val) {
      activeName.value = val.name
      init()
    }

    watch(year, () => {
      init()
    })
    const ytData = [
      { goal: '总目标完成率＜80%', achievement: '0.0%'},
      { goal: '80%≤总目标完成率＜90%', achievement: '1.3%'},
      { goal: '90%≤总目标完成率≤100%', achievement: '1.5%'},
      { goal: '超额完成部分', achievement: '3.0%'},
    ]
    const otherData = [
      { goal: '总目标完成率＜80%', achievement: '0.0%'},
      { goal: '80%≤总目标完成率＜90%', achievement: '2.0%'},
      { goal: '90%≤总目标完成率≤100%', achievement: '2.5%'},
      { goal: '超额完成部分', achievement: '3.0%'},
    ]

    // 组员
    const crew = [
      { goal: '专员', achievement: '80%'},
      { goal: '组长', achievement: '20%'},
    ]

    const tipData = {
      YT_BD: ytData,
      other: otherData,
    }
    const tipColumns = [
      { props: 'goal', label: '目标', },
      { props: 'achievement', label: '成果', align: 'center', width: 80 },
    ]
    const showTip = ref(false)
    return {
      tipData,
      activeName,
      deptMap,
      year,
      tableColumns,
      tableData,
      visibleCount,
      headerCellStyle,
      cellStyle,
      init,
      tabChange,
      loading,
      showTip,
      tipColumns,
      crew,
      lastUpdatedTime
    }
  }
}
</script>

<template>
  <app-view>
    <template #main-header>
      <div class='flex-vertical' style='width: 100%'>
        <TabsPro v-model='activeName' @tabClick='tabChange'>
          <TabPanePro name='commission' label='提成情况' />
          <TabPanePro name='execution' label='小组完成情况' />
        </TabsPro>
      </div>
      <div class='flex-between pt20 pb12'>
        <div class='flex-left gap12'>
          <div style='width: 120px;'>
            <date-picker
              type='year'
              v-model='year'
              append-icon="mdi-calendar-month"
            />
          </div>
          <v-btn v-if="activeName === 'commission'" color='primary' @click='visibleCount = true'>计算</v-btn>
          <div v-if="activeName === 'commission'" class='flex-left fs14 gap4'>
            事业部提成点:
            <v-menu
              v-model='showTip'
              :close-on-content-click='false'
              :nudge-width='400'
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class='mt2'
                  color='primary'
                  size='18'
                  style='cursor: pointer'
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-alert-circle-outline
                </v-icon>
              </template>

              <v-card class='px20'>
                <div class='flex-between' style='height: 50px'>
                  <span class='fs16'>事业部提成点</span>
                  <v-icon size='18' @click.self='showTip = false'>
                    mdi-close
                  </v-icon>
                </div>
                <v-divider />
                <div class='fs14 mt20 mb12'>目标完成率对应提点</div>
                <div style='border: 1px solid #e8eaec; height: 192px; border-right: none; border-bottom: none'>
                  <SuperTable :show-header="false" :border="true" :columns='tipColumns' :data="dept === 'YT_BD' ? tipData['YT_BD'] : tipData['other']" />
                </div>
                <div class='fs14 mt20 mb12'>产出小组专员、组长分配比</div>
                <div style='border: 1px solid #e8eaec; height: 97px; border-right: none; border-bottom: none; overflow: hidden'>
                  <SuperTable :show-header="false" :border="true" :columns='tipColumns' :data="crew" />
                </div>
                <div class='pb20' />
              </v-card>
            </v-menu>

          </div>
        </div>
        <div class='flex-right gap24 fs12'>
          <list-item style='font-weight: bold' color='#FFA80F'>单位： {{ activeName === 'execution' ? '美元' : '人民币' }}</list-item>
          <div style='color: rgba(0,0,0,0.4); flex-shrink: 0'>
            最近更新时间：&nbsp {{ lastUpdatedTime }}
          </div>
        </div>
      </div>
    </template>
    <super-table :column-config="{resizable: true}" :loading='loading' :cell-style="cellStyle" :header-cell-style="headerCellStyle" :columns='tableColumns' :data='tableData' />
    <calculate-commission
      :dept='dept'
      v-if='visibleCount'
      @close='visibleCount = false'
      @success='init'
    />
  </app-view>
</template>

<style scoped lang='scss'>
::v-deep .tab-content-item-box {
  .tab-pane-pro {
    padding: 0;
  }
}
</style>
