<script>
import AppView from '@/components/AppView.vue'
import TabsPro from '@/components/tabs/TabsPro.vue'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import { reactive, ref, nextTick, onMounted, watch } from '@vue/composition-api'
import SuperTable from '@/components/SuperTable.vue'
import SearchForm from '@/components/SearchForm/index.vue'
import ListItem from '@/components/ListItem.vue'
import Detail from './Detail.vue'
import Pagination from '@/components/Pagination.vue'
import Output from './Output.vue'
import Enabling from './Enabling.vue'
import moment from 'moment'
import Dialog from '@/components/Dialog/index.vue'
import ImportOutput from './ImportOutput.vue'
import SetAmount from './SetAmount.vue'
import EnablingAdd from './EnablingAdd.vue'
import { getEmployeeList } from './service'
import EditEnabling from './EditEnabling.vue'
import ImportError from '@/views/business/components/ImportError.vue'

const groupPropertiesType = [
  { text: '产出', value: '1' },
  { text: '赋能', value: '2' }
]

export default {
  name: 'ViewTemplate',
  props: {
    dept: {
      type: String,
      default: ''
    },
  },
  components: {
    ImportError,
    EditEnabling,
    ListItem,
    AppView,
    TabsPro,
    TabPanePro,
    SuperTable,
    SearchForm,
    Detail,
    Pagination,
    Output,
    Enabling,
    Dialog,
    ImportOutput,
    SetAmount,
    EnablingAdd
  },
  setup(props) {
    const activeName = ref('output')
    const searchForm = reactive({
      yearAndMonth: moment().subtract(1, 'month').format('yyyy-MM'),
      employeeName: '',
      groupProperties: '',
      page: 1,
      pageSize: 20,
      total: 0,
      dept: 'YT_BD'
    })
    if (props.dept) {
      searchForm.dept = props.dept
    }
    const searchConfig = ref([
      { el: 'DatePickers', props: 'yearAndMonth', label: '月份', type: "month", clearable: false },
      { el: 'VAutocomplete', props: 'employeeName', label: '姓名', items: [] },
      { el: 'VSelect', props: 'groupProperties', label: '组别性质', items: groupPropertiesType, hidden: false },
    ])
    const outputRef = ref()
    const enablingRef = ref()
    const deptMap = {
      YT_BD: 'YT事业部',
      FB_BD: 'FB事业部',
      SLICE_BD: '影视事业部',
      X_BD: 'X项目',
      ORIGINAL_BD: '原创事业部',
    }

    // 产生数据
    const outputData = reactive({
      updatedAt: '', // 更新时间
      incomeTotalDollar: 0, // 收益合计（美元）
      incomeTotalRmb: 0, // 收益合计（元）
      outputCount: 0, // 产出人数
      settlementOriginal: 0, // 影视结算
      settlementSlice: 0, // 原创结算
      netIncome: 0, // 考核口径收益
      netIncomeRmb: 0, // 考核口径收益人民币
      settlementReplace: 0, // 国内代运营
    })
    // 赋能人数
    const enablingData = reactive({
      updatedAt: '', // 更新时间
      userNum: 0, // 赋能人数
      bonusTotalPercentage: 0 // 奖金包总占比
    })
    function tabChange(val) {
      searchForm.page = 1
      nextTick(() => {
        if (val.name === 'output') {
          outputRef.value.init()
          const findData = searchConfig.value.find(item => item.props === 'groupProperties')
          if (findData) {
            findData.hidden = false
          }
        }
        if (val.name === 'enabling') {
          enablingRef?.value.init()
          const findData = searchConfig.value.find(item => item.props === 'groupProperties')
          if (findData) {
            findData.hidden = true
          }
        }
      })
    }
    const useList = ref([])

    // 按部门获取人员列表
    function getUserList() {
      getEmployeeList({ yearAndMonth: searchForm.yearAndMonth, deptCode:searchForm.dept }).then(({ data }) => {
        if (data.status === 200) {
          const findData = searchConfig.value.find(item => item.props === 'employeeName')
          if (findData) {
            findData.items = data.data.map(item => {
              return {
                text: item.userName,
                value: item.userName,
                id: item.userId
              }
            })
            useList.value = data.data.map(item => ({ text: item.userName, value: item.userId }))
          }
        }
      })
    }

    getUserList()

    function search() {
      if (activeName.value === 'output') {
        outputRef.value.init()
      }
      if (activeName.value === 'enabling') {
        enablingRef.value.init()
      }
    }

    onMounted(() => {
      search()
    })
    function reset() {
      const keys = Object.keys(searchForm)
      keys.forEach(key => {
        if (key === 'page') searchForm[key] = 1
        else if (key === 'yearAndMonth') searchForm[key] = moment().subtract(1, 'month').format('yyyy-MM')
        else if (key === 'pageSize') searchForm[key] = 20
        else if (key === 'dept') searchForm[key] = props.dept
        else searchForm[key] = ''
      })
      search()
    }
    function outputChange(VAL) {
      const oldKeys = Object.keys(outputData)
      oldKeys.forEach(key => {
        outputData[key] = VAL?.[key] || '-'
      })
    }
    function enablingChange(VAL) {
      const oldKeys = Object.keys(enablingData)
      oldKeys.forEach(key => {
        enablingData[key] = VAL?.[key] || '-'
      })
    }
    const show = ref(false)
    const importShow = ref(false)
    const outputDetail = ref({})
    function openDetail(row) {
      show.value = true
      outputDetail.value = row
    }

    const setAmountShow = ref(false)
    // 显示状态
    const visibleEnablingAdd = ref(false)

    watch(() => searchForm.yearAndMonth, () => {
      getUserList()
      searchForm.employeeName = ''
    })

    const enablingRow = ref({})
    const visibleEnablingEdit = ref(false)
    function editEnabling(row) {
      enablingRow.value = row
      visibleEnablingEdit.value = true
    }
    function handleSizeChange(val) {
      searchForm.page = 1
      searchForm.pageSize = val
      search()
    }

    function handleCurrentChange(val) {
      searchForm.page = val
      search()
    }

    function totalChange(val) {
      searchForm.total = val
    }

    const showError = ref(false)
    const errorList = ref([])

    function openError(val) {
      showError.value = true
      errorList.value = val
    }
    const isItCovered = ref(false)

    return {
      outputDetail,
      visibleEnablingEdit,
      enablingRow,
      searchForm,
      searchConfig,
      activeName,
      tabChange,
      outputRef,
      enablingRef,
      outputData,
      enablingData,
      visibleEnablingAdd,
      search,
      reset,
      outputChange,
      enablingChange,
      openDetail,
      show,
      importShow,
      setAmountShow,
      useList,
      deptMap,
      showError,
      errorList,
      openError,
      editEnabling,
      handleSizeChange,
      handleCurrentChange,
      totalChange,
      isItCovered
    }
  }
}
</script>

<template>
  <app-view>
    <template #header>
      <div class='flex-vertical' style='width: 100%'>
        <tabs-pro v-model='activeName' @tabClick='tabChange'>
          <tab-pane-pro label="月度业务数据（产出）" name="output" />
          <tab-pane-pro label="月度业务数据（赋能）" name="enabling" />
        </tabs-pro>
        <SearchForm :columns="searchConfig" :value="searchForm" :search='search' :reset='reset' />
      </div>
    </template>

    <template #main-header>
      <div class='list-header'>
        <div class='flex-left gap12 pb17'>
          <template v-if="activeName === 'output'">
            <v-btn color="primary" @click='importShow = true'>导入</v-btn>
            <v-btn v-if="['YT_BD', 'FB_BD'].includes(dept)" outlined @click='setAmountShow = true'>设置内部结算金额</v-btn>
          </template>
          <template v-else>
            <v-btn color="primary" @click='visibleEnablingAdd = true'>新增</v-btn>
          </template>
        </div>
        <div class='table-top-message flex-between px10 fs12'>
          <div v-if="activeName === 'output'" class='flex-left gap24' style='font-weight: bold'>
            <list-item color='#FFA80F'>产出人数 &nbsp{{ outputData.outputCount }}</list-item>
            <list-item color='#9155FD'>归属收益合计 &nbsp $ {{ outputData.incomeTotalDollar }} / ¥ {{ outputData.incomeTotalRmb }}</list-item>
            <list-item v-if="dept !== 'X_BD'" color='#00B09B'>{{ ['YT_BD', 'FB_BD'].includes(dept) ? '影视结算' : 'FB结算'}} &nbsp  $ {{ outputData.settlementSlice }}</list-item>
            <list-item v-if="dept !== 'X_BD'" color='#2CA0F8'>{{ ['YT_BD', 'FB_BD', 'X_BD'].includes(dept) ? '原创结算' : 'YT结算'}} &nbsp  $ {{ outputData.settlementOriginal }}</list-item>
            <list-item v-if="dept === 'YT_BD'" color='#2CA0F8'>国内代运营 &nbsp  $ {{ outputData.settlementReplace }}</list-item>
            <list-item v-if="dept !== 'X_BD'" color='#FFA80F'>考核口径收益 &nbsp  $ {{ outputData.netIncome }} / ¥ {{ outputData.netIncomeRmb }}</list-item>
          </div>
          <div v-else class='flex-left gap24' style='font-weight: bold'>
            <list-item color='#FFA80F'>赋能人数 &nbsp {{ enablingData.userNum }}</list-item>
            <list-item color='#9155FD'>奖金包总占比 &nbsp  {{ enablingData.bonusTotalPercentage }}</list-item>
          </div>
          <div style='color: rgba(0,0,0,0.4); flex-shrink: 0'>
            最近更新时间：{{ (activeName === 'output' ? outputData.updatedAt : enablingData.updatedAt) || '-' }}
          </div>
        </div>
      </div>
    </template>

    <Output
      ref='outputRef'
      v-show="activeName === 'output'"
      :searchForm='searchForm'
      @openDetail='openDetail'
      @change='outputChange'
      @totalChange='totalChange'
      @isItCovered='e => isItCovered = e'
    />
    <Enabling
      v-show="activeName === 'enabling'"
      ref='enablingRef'
      :searchForm='searchForm'
      @change='enablingChange'
      @editEnabling='editEnabling'
      @totalChange='totalChange'
    />

    <template #footer>
      <Pagination
        :page-size="searchForm.pageSize"
        :page-index="searchForm.page"
        :total="searchForm.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
    <!-- 明细 -->
    <Detail
      v-if='show'
      :dept='searchForm.dept'
      :year-and-month='searchForm.yearAndMonth'
      :employee-user-id='outputDetail.employeeUserId'
      :name='outputDetail.userName'
      :oneDeptName='outputDetail.oneDeptName'
      :threeDeptId='outputDetail.threeDeptId'
      :twoDeptId='outputDetail.twoDeptId'
      @close='show = false'
    />
    <!-- 导入数据 -->
    <import-output
      v-if='importShow'
      :is-it-covered='isItCovered'
      :year-and-month='searchForm.yearAndMonth'
      :dept='searchForm.dept'
      @close='importShow = false'
      @success='search'
      @error='openError'
    />
    <!-- 设置内部结算金额 -->
    <SetAmount
      v-if='setAmountShow'
      :year-and-month='searchForm.yearAndMonth'
      :dept='searchForm.dept'
      @close='setAmountShow = false'
      @refresh='search'
    />

    <!-- 赋能添加新的人员 -->
    <EnablingAdd
      v-if='visibleEnablingAdd'
      :year-and-month='searchForm.yearAndMonth'
      :dept='searchForm.dept'
      :useList='useList'
      @close='visibleEnablingAdd = false'
      @success='search'
    />

    <!-- 编辑赋能人员 -->
    <EditEnabling
      v-if='visibleEnablingEdit'
      :row='enablingRow'
      @close='visibleEnablingEdit = false'
      @success='search'
    />

    <ImportError
      v-if='showError'
      :list='errorList'
      @close='showError = false'
    />
  </app-view>
</template>

<style scoped lang='scss'>
::v-deep .tab-content-item-box {
  .tab-pane-pro {
    padding: 0;
  }
}
.table-top-message {
  flex-wrap: wrap;
  line-height: 37px;
  border-radius: 4px;
  background: rgba(255,168,15,0.08);
}
</style>
