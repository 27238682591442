<script>
import { computed, ref, nextTick } from '@vue/composition-api'
import SuperTable from '@/components/SuperTable.vue'
import Dialog from '@/components/Dialog/index.vue'
import ListItem from '@/components/ListItem.vue'
import { addEnabling, getOutputDetail } from './service'
import { getUUID } from '@core/utils'
import { useMessage } from '@/hooks/useMessage'

export default {
  name: 'Detail',
  components: { ListItem,SuperTable, Dialog },
  props: {
    yearAndMonth: String,
    dept: String,
    useList: {
      type: Array,
      default: () => ([])
    }
  },
  setup(props, { emit }) {
    const show = ref(true)
    const tableColumns = [
      { props: 'index', label: '序号', type: 'seq', width: 60},
      { props: 'userId', label: '姓名' },
      { props: 'bonusPercentage', label: '奖金包占比' },
      { props: 'operate', label: '编辑', width: 60 },
    ]
    const tableRef = ref()
    const tableData = ref([])
    const { message } = useMessage()

    function deleteRow(index) {
      tableData.value.splice(index, 1)
    }

    function add() {
      if (tableData.value.length === 20) {
        return message.warning('最多只能添加20条数据！')
      }
      const data = { userId: '', bonusPercentage: '', yearAndMonth: props.yearAndMonth, deptCode: props.dept, keyId: getUUID()  }
      tableData.value.push(data)
      nextTick(() => {
        setTimeout(() => {
          tableRef.value.tableRef.scrollToRow(data);
        }, 300)
      })
    }

    function close() {
      show.value = false
      emit('close')
    }

    function submit() {
      const dat = tableData.value.find(item => !item.userId || !item.bonusPercentage)
      if (dat) return message.warning('请将数据填写完整！')
      const data = tableData.value.map(item => {
        const user = props.useList.find(val => item.userId === val.value)
        return {
          userId: item.userId,
          userName: user.text,
          bonusPercentage: Number(item.bonusPercentage),
          yearAndMonth: props.yearAndMonth,
          deptCode: props.dept,
        }
      })

      addEnabling(data).then(({ data }) => {
        if (data.status === 200) {
          message.success('保存成功！')
          emit('success')
          close()
        }
      }).catch((error) => {
        message.error(error || '保存失败，请稍后重试！')
      })
    }

    function validateInput(e, index) {
      if (Number(e) > 100) {
        tableData.value[index].bonusPercentage = 100
      }
      if (Number(e) < 0) {
        tableData.value[index].bonusPercentage = 0
      }
    }

    return {
      show,
      tableColumns,
      tableData,
      tableRef,
      add,
      deleteRow,
      submit,
      close,
      validateInput,
    }
  }
}
</script>

<template>
  <Dialog show-footer v-model="show" title='新增' width='500px'  @confirm='submit'  @cancel='close'>
    <div style='height: 300px'>
      <SuperTable ref='tableRef' :data="tableData" :columns="tableColumns">
        <template #userId='{ index }'>
          <div class='flex-center' style='height: 100%'>
            <v-autocomplete :key='index' v-model='tableData[index].userId' dense outlined :items="useList" hide-details />
          </div>
        </template>
        <template #bonusPercentage='{ index }'>
          <div class='flex-center' style='height: 100%'>
            <v-text-field
              v-model='tableData[index].bonusPercentage'
              :key='index'
              type='number'
              dense
              outlined
              hide-details
              @change="e => validateInput(e, index)"
            >
              <template #append>
                <span class='flex-center fs15'>%</span>
              </template>
            </v-text-field>
          </div>
        </template>
        <template #operate='{ row, index }'>
          <v-icon color='#f36261' @click='deleteRow(index)'>
            mdi-minus-circle-outline
          </v-icon>
        </template>
        <template #empty>
          <span>暂无配置</span>
        </template>
      </SuperTable>
    </div>
    <div class='add-button flex-center' :class='{ emptyButton: tableData.length === 0 }' @click='add'>
      <v-icon color='primary' class="mr8">
        mdi-plus-circle-outline
      </v-icon>
      新增
    </div>
  </Dialog>
</template>

<style scoped lang='scss'>
::v-deep .v-input__append-inner {
  height: 25px;
}

.add-button {
  border: 1px solid #e5e5e5;
  border-top: none;
  border-radius: 4px;
  height: 48px;
  color: #9155fd;
  transform: translateY(-2px);
  cursor: pointer;
}

.emptyButton {
  border-top: 1px solid #e5e5e5;
}
</style>
