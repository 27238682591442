<script>
export default {
  name: 'VTag',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    color: {
      type: String,
      default: 'default'
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div
    class='v-tag px6'
    :class="{
      'v-tag--warning': type === 'warning',
      'v-tag--success': type === 'success',
    }"
  >
    {{ text }}
  </div>
</template>

<style scoped lang='scss'>
.v-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFangSC, PingFang SC,serif;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 18px;
  text-align: left;
  font-style: normal;

  &--warning {
    background: #FFA80F;
    border-radius: 4px;
    border: 1px solid #FFA80F;
  }

  &--success {
    background: #00B09B;
    border-radius: 4px;
    border: 1px solid #00B09B;
  }
}
</style>
