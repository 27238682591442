<script>
import { computed, ref, watch } from '@vue/composition-api'
import { isNull } from '@core/utils/is'

export default {
  name: 'DatePicker',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    multiple: Boolean,
    value: {
      type: [String, Array],
      default: () => null,
    },
    range: Boolean,
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rangeSeparator: {
      type: String,
      default: '-',
    },
    type: {
      type: String,
      default: 'date',
    },
    clearable: Boolean,
    appendIcon: {
      type: String,
      default: ''
    },
    prependInnerIcon: {
      type: String,
      default: ''
    },
    yearStart: {
      type: Number,
      default: () => 2010,
    },
    yearEnd: {
      type: Number,
      default: () => 2029,
    },
  },
  setup(props, { emit }) {
    const pickers = ref()
    const dateValue = computed({
      get() {
        if (pickers.value) return pickers.value

        return props.value
      },
      set() {},
    })
    const startYear = ref(Number(props.yearStart))
    const endYear = ref(Number(props.yearEnd))
    const yearList = computed(() => {
      const list  = []
      for (let i = startYear.value; i <= endYear.value; i++) {
        list.push(i)
      }
      return list
    })

    const isRange = computed(() => props.range)
    const isMultiple = computed(() => props.multiple)
    const show = ref(false)
    const text = computed({
      get() {
        if (props.range && (!dateValue.value[0] && !dateValue.value[1])) {
          return []
        }

        return props.multiple || props.range ? dateValue.value.join(`   ${props.rangeSeparator}   `) : dateValue.value
      },
      set(val) {
        if (isNull(val) && (isMultiple.value || isRange.value)) return emit('input', [])
        if (isNull(val)) return emit('input', undefined)
      },
    })

    if ((isRange.value || isMultiple.value) && !Array.isArray(props.value)) throw new Error('期望是Array类型')
    if (!isRange.value && !isMultiple.value && Array.isArray(props.value)) throw new Error('期望是String类型')

    function close(val) {
      if (props.range) val.sort((a, b) => new Date(a) - new Date(b))

      emit('input', val)
      show.value = false
    }

    watch(() => pickers.value, val => {
      emit('input', val)
    })

    watch(() => props.value, val => { pickers.value = val }, { immediate: true })

    function toLeft() {
      startYear.value -= 20
      endYear.value -= 20
    }

    function toRight() {
      startYear.value += 20
      endYear.value += 20
    }

    return {
      show,
      isMultiple,
      text,
      isRange,
      dateValue,
      pickers,
      close,
      yearList,
      startYear,
      endYear,
      toLeft,
      toRight,
    }
  },
}
</script>

<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    min-width="auto"
    nudge-top="-8"
    offset-y
    transition="scale-transition"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-if="!isMultiple"
        v-model="text"
        :clearable="clearable"
        :label="label"
        :placeholder="placeholder"
        :style="isRange ? { minWidth: '250px', flexShrink: 0 } : {}"
        dense
        hide-details
        outlined
        readonly
        single-line
        :append-icon='appendIcon'
        :prepend-inner-icon='prependInnerIcon'
        class="form-datepicker"
        v-bind="attrs"
        v-on="on"
      />
      <v-combobox
        v-else
        v-model="dateValue"
        :clearable="clearable"
        chips
        label="Multiple picker in menu"
        multiple
        prepend-icon="mdi-calendar"
        readonly
        small-chips
        single-line
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-if="type !== 'year'"
      no-title
      v-model="pickers"
      :multiple="multiple"
      :range="range"
      :type='type'
      v-bind="$attrs"
      @change="close"
    />
    <div v-else class='year flex-vertical'>
      <div class='year-header py12 px30'>
        <div>
          <v-icon @click='toLeft'>
            mdi-chevron-triple-left
          </v-icon>
        </div>
        <div>
          <span class='mx12'>{{ startYear }}</span>
          ~
          <span class='mx12'>{{ endYear }}</span>
        </div>
        <div>
          <v-icon @click='toRight'>
            mdi-chevron-triple-right
          </v-icon>
        </div>
      </div>
      <div class='year-body'>
        <div
          v-for='(item, index) in yearList'
          :key='index'
          class='year-body-item flex-center'
          :class="{
            'active-item': String(text) === String(item)
          }"
          @click="close(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </v-menu>
</template>
<style lang="scss" scoped>
.year {
  width: 300px;
  background-color: #fff;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
  }

  &-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px;

    &-item {
      width: calc(25% - 8px);
      font-size: 14px;
      height: 30px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }

    .active-item {
      background-color: var(--v-primary-base);
      color: #fff;
    }
  }
}
</style>
