<script>
import ProcessTemplate from '@/views/process/components/ProcessTemplate.vue'

export default {
  name: 'List',
  components: { ProcessTemplate },
}
</script>

<template>
  <ProcessTemplate dept='YT_BD' />
</template>
