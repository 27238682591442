<script>
import AppView from '@/components/AppView.vue'
import { ref, watch } from '@vue/composition-api'
import SearchFormItem from '@/components/SearchForm/SearchFormItem.vue'
import DatePicker from '@/components/DatePicker.vue'
import moment from 'moment/moment'
import VTag from './components/VTag.vue'
import { useRouter } from '@core/utils'
import { getEnablingList } from '@/views/calculate/service'
import SetUpResponsiblePerson from '@/views/calculate/SetUpResponsiblePerson.vue'

export default {
  name: 'List',
  components: { SetUpResponsiblePerson, SearchFormItem, AppView, DatePicker, VTag },
  setup() {
    const {router} = useRouter()
    const yearAndMonth = ref(moment().subtract(1, 'month').format('yyyy-MM'))
    const prohibitedMonth = ref(moment().subtract(1, 'month').format('yyyy-MM'))
    const departmentList = ref([])
    const peopleRow = ref({})
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRoles = userData.role || []

    function jump(item) {
      router.push({
        path:'/calculate/Details',
        query: {
          type: item.deptCode,
          month: yearAndMonth.value,
          status: item.status || 0
        }
      })
    }

    function init() {
      getEnablingList({ yearAndMonth: yearAndMonth.value }).then(({ data }) => {
        if (data.status === 200) {
          if (userRoles.includes('超级管理员')) {
            departmentList.value = data.data || []
          } else {
            // 登录人ID
            const userId = userData.user.userId
            const arr = (data.data || []).filter(item => {
              if (item.chargePersonId && item.chargePersonId?.split(',').some(item => item === userId)) {
                return item
              }
            })
            departmentList.value = arr || []
          }
        }
      })
    }

    init()


    watch(yearAndMonth, () => {
      init()
    })

    const visiblePeople = ref(false)
    function openPeople(row) {
      peopleRow.value = row
      visiblePeople.value = true
    }

    return {
      yearAndMonth,
      prohibitedMonth,
      departmentList,
      peopleRow,
      visiblePeople,
      openPeople,
      jump,
      init,
    }
  }
}
</script>

<template>
  <app-view>
    <div>
      <div class='selectMonth'>
        <date-picker
          type='month'
          v-model='yearAndMonth'
          append-icon="mdi-calendar-month"
          max='2099-12'
        />
      </div>
    </div>
    <div class='module-box pt16 gap20'>
      <div
        v-for='(item, index) in departmentList'
        :key='index'
        class='card-module p20'
        @click='jump(item)'
      >
        <div class='card-module-left gap15'>
          <div class='flex-between'>
            <div class='flex-left gap8 fs18' style='font-weight: 600; color: rgba(0,0,0,0.8)'>
              <v-tag :type="item.status !== 1 ? 'warning' : 'success'" :text='item.status !== 1 ? "待核算" : "已核算"' />
              {{ item.deptName }}
            </div>
            <div class='arrow'>
              <img src='@/assets/images/arrow-right.png' alt='arrow' style='width: 20px'>
            </div>
          </div>
          <div class='fs14'>
            <span class='mr5'>核算周期&nbsp</span>
            {{ item.accountingCycle || '-' }}
          </div>
          <div class='fs14'>
            <span class='mr5'>负责人&nbsp</span>
            <span class='fs13'>{{ item.chargePerson }}</span>
            <span v-auth="['超级管理员']" class='app-link fs12 ml12' @click.stop='openPeople(item)'>设置负责人</span>
          </div>
        </div>
        <v-divider class='my12 dividerHr' />
        <div class='card-module-right'>
          <div class='flex-vertical-center gap5'>
            <div class='money'>{{ item.calculationUserNum !== undefined ? item.calculationUserNum : '-' }}</div>
            <div class='fs12'>核算人数</div>
          </div>
          <v-divider vertical inset class='dividerHr' />
          <div class='flex-vertical-center gap5'>
            <div class='money'>{{ item.commission !== undefined ? item.commission : '-' }}</div>
            <div class='fs12'>应发提成(￥)</div>
          </div>
          <v-divider vertical inset class='dividerHr'/>
          <div class='flex-vertical-center gap5'>
            <div class='money'>{{ item.realIncome !== undefined ? item.realIncome : '-' }}</div>
            <div class='fs12'>实际收入(￥)</div>
          </div>
        </div>
      </div>
    </div>
    <SetUpResponsiblePerson
      v-if='visiblePeople'
      :dept='peopleRow.deptCode'
      :row='peopleRow'
      :yearAndMonth='yearAndMonth'
      @close='visiblePeople = false'
      @success='init'
    />
  </app-view>
</template>

<style scoped lang='scss'>
@mixin flex {
  display: flex;
}

.module-box {
  display: flex;
  flex-wrap: wrap;

  .card-module {
    @include flex;
    flex-direction: column;
    width: 360px;
    border-radius: 6px;
    border: 1px solid #E8E9EC;
    cursor: pointer;
    color: rgba(0,0,0,0.5);

    .arrow {
      display: none;
    }

    &:hover {
      background: linear-gradient( 246deg, #F8F3FF 0%, #F7F3FF 5%, #F2FAFF 82%, #F9FDFF 100%);
      box-shadow: 0 4px 30px 0 rgba(15,30,64,0.06);
      border-radius: 6px;
      border: 2px solid;
      border-image: linear-gradient(315deg, rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 1)) 2 2;

      .arrow {
        display: inline-block;
      }
    }

    &-left {
      @include flex;
      flex-direction: column;
    }

    &-right {
      @include flex;
      justify-content: space-around;
      gap: 12px;
    }
  }
}

.selectMonth {
  width: 120px;
}
.money {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.88);
  line-height: 22px;
}

.dividerHr {
  border-color: #F6F7F8;
}
</style>
