<script>
import { reactive, ref } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { getInternalSettlement, setInternalSettlement } from './service'
import SearchFormItem from '@/components/SearchForm/SearchFormItem.vue'
import { required } from '@core/utils/validation'
import { asynchronousReturn } from '@core/utils'
import { useMessage } from '@/hooks/useMessage'

export default {
  name: 'SetAmount',
  components: { SearchFormItem, Dialog },
  props: {
    dept: String,
    yearAndMonth: String
  },
  setup(props, { emit }) {
    const show = ref(true)
    const formRef = ref()
    const formData = reactive({
      SLICE_BD: '0',
      ORIGINAL_BD: '0',
      REPLACE_BD: '0', // 国内代运营
    })

    const { message } = useMessage()

    function close() {
      show.value = false
      emit('close')
    }

    function init() {
      getInternalSettlement({ yearAndMonth: props.yearAndMonth, deptCode: props.dept }).then(({ data }) => {
        if (data.status === 200) {
          if (data.data.length > 0) {
            const ORIGINAL_BD = data.data.find(item =>  item.receiveDept === 'ORIGINAL_BD')
            const SLICE_BD = data.data.find(item => item.receiveDept === 'SLICE_BD')
            const REPLACE_BD = data.data.find(item => item.receiveDept === 'REPLACE_BD')
            formData.ORIGINAL_BD = String(ORIGINAL_BD.amount)
            formData.SLICE_BD = String(SLICE_BD.amount)
            if (REPLACE_BD) {
              formData.REPLACE_BD = String(REPLACE_BD.amount)
            }
          }
        }
      })
    }
    init()

    function submit() {
      asynchronousReturn(formRef.value.validate).then(() => {
        const data = []
        const keys = Object.keys(formData)
        keys.forEach(key => {
          data.push({
            amount: Number(formData[key]),
            receiveDept: key,
            targetDept: props.dept,
            yearAndMonth: props.yearAndMonth
          })
        })

        setInternalSettlement(data).then(({ data }) => {
          if (data.status === 200) {
            close()
            message.success('设置成功')
            emit('refresh')
          }
        }).catch(err => {
          message.error(err)
        })
      }).catch(() => {
        message.warning('数据未填写完整或长度过长')
      })
    }

    return {
      show,
      formData,
      formRef,
      submit,
      close,
      required
    }
  }
}
</script>

<template>
  <Dialog v-model="show" title='设置内部结算金额' width='400px' show-footer @confirm='submit' @cancel='close' >
    <div class='fs12 mb8' style='color: #F24545;'>注：支出请输入负值，收入为正值；单位为美元</div>
    <v-form ref='formRef'>
      <SearchFormItem label='原创事业部' class='fs14'>
        <v-text-field v-model='formData.ORIGINAL_BD' :rules="[required, v => (v && v.length <= 9) || ' ']" placeholder="请输入" type='number' dense outlined>
          <template #append>
            <span class='flex-center fs14'>美元</span>
          </template>
        </v-text-field>
      </SearchFormItem>
      <SearchFormItem label='影视事业部' class='mt12 fs14'>
        <v-text-field v-model='formData.SLICE_BD' :rules="[required, v => (v && v.length <= 9) || ' ']" placeholder="请输入" type='number' dense outlined>
          <template #append>
            <span class='flex-center fs14'>美元</span>
          </template>
        </v-text-field>
      </SearchFormItem>
      <SearchFormItem v-if="dept === 'YT_BD'" label='国内代运营' class='mt12 fs14'>
        <v-text-field v-model='formData.REPLACE_BD' :rules="[required, v => (v && v.length <= 9) || ' ']" placeholder="请输入" type='number' dense outlined>
          <template #append>
            <span class='flex-center fs14'>美元</span>
          </template>
        </v-text-field>
      </SearchFormItem>
    </v-form>
  </Dialog>
</template>

<style scoped lang='scss'>
::v-deep .v-input__append-inner {
  height: calc(100% - 14px);
}
</style>
