<script>
import { reactive, ref } from '@vue/composition-api'
import SuperTable from '@/components/SuperTable.vue'
import Pagination from '@/components/Pagination.vue'
import Dialog from '@/components/Dialog/index.vue'
import ListItem from '@/components/ListItem.vue'
import { getOutputDetail } from './service'

export default {
  name: 'Detail',
  components: { ListItem, Pagination, SuperTable, Dialog },
  props: {
    employeeUserId: [String, Number],
    yearAndMonth: String,
    name: String,
    dept: String,
    oneDeptName: String,
    threeDeptId: String,
    twoDeptId: String,
  },
  setup(props, { emit }) {
    const show = ref(true)
    const tableColumns = [
      { props: 'index', label: '序号', type: 'seq'},
      { props: 'channelId', label: '频道ID' },
      { props: 'incomeDollar', label: '收益($)', align: 'right' },
      { props: 'incomeRmb', label: '收益(￥)', align: 'right' },
      { props: 'proportion', label: '分成比例', align: 'right' },
      { props: 'attributableDollar', label: '归属收益($)', align: 'right' },
      { props: 'attributableRmb', label: '归属收益(￥)', align: 'right' },
    ]
    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })
    const topData = reactive({
      userName: '', // 姓名
      channelIdNum: '', // 频道数量
      incomeDollar: '', // 收益合计(美元)
      incomeRmb: '', // 收益合计(元)
    })
    const tableData = ref([])


    function init() {
      const data = {
        ...page,
        total: undefined,
        employeeUserId: props.employeeUserId,
        yearAndMonth: props.yearAndMonth,
        deptCode: props.dept,
        oneDeptName: props.oneDeptName,
        threeDeptId: props.threeDeptId,
        twoDeptId: props.twoDeptId,
      }
      getOutputDetail(data).then(({ data }) => {
        if (data.status === 200) {
          const { pageData } = data.data
          page.total = pageData.total
          tableData.value = (pageData.records || []).map(item => ({ ...item, proportion: `${item.proportion}%` }))

          const keys = Object.keys(topData)
          keys.forEach(key => {
            topData[key] = data.data[key] || '-'
          })
        }
      })
    }

    init()

    function close() {
      show.value = false
      const time = setTimeout(() => {
        emit('close')
        clearTimeout(time)
      }, 300)
    }
    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    return {
      show,
      tableColumns,
      tableData,
      topData,
      page,
      close,
      handleSizeChange,
      handleCurrentChange
    }
  }
}
</script>

<template>
  <Dialog v-model="show" title='收益明细' width='1000px'  @cancel='close'>
    <div class='table-top flex-left fs12 gap12 mb8 px10' style='line-height: 37px; font-weight: bold'>
      <list-item color='#FFA80F'>姓名&nbsp {{ name }}</list-item>
      <list-item color='#9155FD'>频道数量&nbsp {{ topData.channelIdNum }}</list-item>
      <list-item color='#00B09B'>归属收益合计($)&nbsp {{ topData.incomeDollar }}</list-item>
      <list-item color='#2CA0F8'>归属收益合计(￥)&nbsp {{ topData.incomeRmb }}</list-item>
    </div>
    <div style='height: 450px'>
      <SuperTable :data="tableData" :columns="tableColumns" />
    </div>
    <div>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </Dialog>
</template>

<style scoped lang='scss'>
.table-top {
  flex-wrap: wrap;
  line-height: 37px;
  border-radius: 4px;
  background: rgba(255,168,15,0.08);
}
</style>
