<script>
export default {
  name: 'VlDialog',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: Boolean,
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '500px',
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showFooter: Boolean,
    confirmBtnText: {
      type: String,
      default: '确定',
    },
    cancelBtnText: {
      type: String,
      default: '取消',
    },
    showCancelBtnText: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<template>
  <v-dialog :value="value" :persistent='value' :width='width'>
    <div class='dialog'>
      <div class='dialog-header'>
        <div class='dialog-header-title'>{{ title }}</div>
        <i v-if="showClose" class="iconfont close fs18" @click='$emit("cancel")' />
      </div>
      <hr class='message_hr' />
      <div class='dialog-body'>
        <slot />
      </div>
      <div v-if='showFooter' class='dialog-footer'>
        <v-btn v-if='showCancelBtnText' outlined @click='$emit("cancel")'>{{ cancelBtnText }}</v-btn>
        <v-btn color="primary" @click='$emit("confirm")'>{{ confirmBtnText }}</v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped lang='scss'>
.dialog {
  background: #FFFFFF;
  border-right: 4px;
  padding: 0 20px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    &-title {
      font-weight: 600;
      font-size: 20px;
    }

    .iconfont {
      font-size: 18px !important;
      cursor: pointer;
    }
  }

  &-body {
    padding: 20px 0;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding-bottom: 20px;
  }

  .message_hr {
    background-color: #F2F3F5;
    height: 1px;
    border: none;
  }
}
</style>
