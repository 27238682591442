const formConfigMap = {
  // 运营一组、运营二组、运营三组、运营四组、运营五组、运营六组、运营七组
  YT_BD: [
    { groupName: '运营1组', targetRevenue: '0'  },
    { groupName: '运营2组', targetRevenue: '0'  },
    { groupName: '运营3组', targetRevenue: '0'  },
    { groupName: '运营4组', targetRevenue: '0'  },
    { groupName: '运营5组', targetRevenue: '0'  },
    { groupName: '运营6组', targetRevenue: '0'  },
    { groupName: '运营7组', targetRevenue: '0'  },
    { groupName: '自动运营组', targetRevenue: '0'  },
  ],
  FB_BD: [
    { groupName: '运营1组', targetRevenue: '0'  },
    { groupName: '运营2组', targetRevenue: '0'  },
    { groupName: '运营3组', targetRevenue: '0'  },
    { groupName: '运营4组', targetRevenue: '0'  },
    { groupName: '运营5组', targetRevenue: '0'  },
    { groupName: '运营6组', targetRevenue: '0'  }
  ],
  SLICE_BD: [
    { groupName: '女频一组', targetRevenue: '0'  },
    { groupName: '女频二组', targetRevenue: '0'  },
    { groupName: '男频组', targetRevenue: '0'  },
  ],
  X_BD: [
    { groupName: 'Tik Tok项目组', targetRevenue: '0'  },
    { groupName: 'Twitter项目组', targetRevenue: '0'  },
    { groupName: 'TH小组', targetRevenue: '0'  },
    { groupName: 'INS项目组', targetRevenue: '0'  },
  ]
}

export default formConfigMap
