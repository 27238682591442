<script>
import Dialog from '@/components/Dialog/index.vue'
import { ref } from '@vue/composition-api'
import SearchFormItem from '@/components/SearchForm/SearchFormItem.vue'
import { useMessage } from '@/hooks/useMessage'
import { editEnabling } from './service'
export default {
  name: 'EditEnabling',
  components: { Dialog, SearchFormItem },
  props: {
    row: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const show = ref(true)
    const { message } = useMessage()
    const bonusPercentage = ref(props.row.bonusPercentage.replace(/%/g, ''))
    function close() {
      show.value = false
      emit('close')
    }

    function submit() {
      if (bonusPercentage.value === '') return message.error('奖金包占比不能为空！')
      const data = {
        bonusPercentage: bonusPercentage.value,
        id: props.row.id
      }
      editEnabling(data).then(({ data }) => {
        if (data.status === 200) {
          message.success('保存成功！')
          emit('success')
          close()
        }
      }).catch((error) => {
        message.error(error || '保存失败，请稍后重试！')
      })
    }

    function validateInput(e) {
      if (Number(e) > 100) {
        bonusPercentage.value = 100
      }
      if (Number(e) < 0) {
        bonusPercentage.value = 0
      }
    }

    return {
      show,
      bonusPercentage,
      close,
      submit,
      validateInput
    }
  }
}
</script>

<template>
  <Dialog show-footer v-model="show" title='编辑' width='400px'  @confirm='submit'  @cancel='close'>
    <div class='fs14' style='font-weight: 500'>
      <SearchFormItem label='奖金包占比'>
        <v-text-field v-model='bonusPercentage' @change='validateInput' type='number' dense outlined hide-details>
          <template #append>
            <span class='flex-center fs15'>%</span>
          </template>
        </v-text-field>
      </SearchFormItem>
    </div>
  </Dialog>
</template>

<style scoped lang='scss'>
::v-deep .v-input__append-inner {
  height: calc(100% - 15px);
}
</style>
