var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"show-footer":"","title":"新增","width":"500px"},on:{"confirm":_vm.submit,"cancel":_vm.close},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticStyle:{"height":"300px"}},[_c('SuperTable',{ref:"tableRef",attrs:{"data":_vm.tableData,"columns":_vm.tableColumns},scopedSlots:_vm._u([{key:"userId",fn:function(ref){
var index = ref.index;
return [_c('div',{staticClass:"flex-center",staticStyle:{"height":"100%"}},[_c('v-autocomplete',{key:index,attrs:{"dense":"","outlined":"","items":_vm.useList,"hide-details":""},model:{value:(_vm.tableData[index].userId),callback:function ($$v) {_vm.$set(_vm.tableData[index], "userId", $$v)},expression:"tableData[index].userId"}})],1)]}},{key:"bonusPercentage",fn:function(ref){
var index = ref.index;
return [_c('div',{staticClass:"flex-center",staticStyle:{"height":"100%"}},[_c('v-text-field',{key:index,attrs:{"type":"number","dense":"","outlined":"","hide-details":""},on:{"change":function (e) { return _vm.validateInput(e, index); }},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"flex-center fs15"},[_vm._v("%")])]},proxy:true}],null,true),model:{value:(_vm.tableData[index].bonusPercentage),callback:function ($$v) {_vm.$set(_vm.tableData[index], "bonusPercentage", $$v)},expression:"tableData[index].bonusPercentage"}})],1)]}},{key:"operate",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('v-icon',{attrs:{"color":"#f36261"},on:{"click":function($event){return _vm.deleteRow(index)}}},[_vm._v(" mdi-minus-circle-outline ")])]}},{key:"empty",fn:function(){return [_c('span',[_vm._v("暂无配置")])]},proxy:true}])})],1),_c('div',{staticClass:"add-button flex-center",class:{ emptyButton: _vm.tableData.length === 0 },on:{"click":_vm.add}},[_c('v-icon',{staticClass:"mr8",attrs:{"color":"primary"}},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" 新增 ")],1)])}
var staticRenderFns = []

export { render, staticRenderFns }